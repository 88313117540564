import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Bolivia",
  "subtitle": "October 2016",
  "category": "South America"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul">{`Local SIM - I went to `}<a parentName="li" {...{
          "href": "https://www.tigo.com.bo/"
        }}>{`Tigo's`}</a>{` flagship`}</li>
      <li parentName="ul">{`Be cautious about flying straight into La Paz if you’re unsure about altitude sickness. Flying into the sea level Santa Cruz first will make acclimatization easier. I got a prescription for Acetazolamide (altitude sickness medication) but didn't end up needing it.`}</li>
      <li parentName="ul">{`There are a lot of shared “micros” which are mini vans and buses. I mostly opted to hire mostly private taxis instead.`}</li>
      <li parentName="ul">{`Beware of roadblocks that shut down stretches of roads. I had tickets booked on `}<a parentName="li" {...{
          "href": "https://www.ticketsbolivia.com/"
        }}>{`Expreso Del Sur`}</a>{` but ended up taking a flight!`}</li>
    </ul>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Bu%C3%B1uelo"
        }}>{`Buñuelo`}</a>{` - Fried dough`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Coca_tea"
        }}>{`Mate de coca`}</a>{` - Locals say it helps with altitude sickness`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Pique_macho"
        }}>{`Pique a lo macho`}</a>{` - Meat, fries, vegetables and an egg`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Salte%C3%B1a"
        }}>{`Salteñas`}</a>{` - Empanadas are popular here as they’re baked and sold in the morning for breakfast`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://es.wikipedia.org/wiki/S%C3%A1ndwich_de_chola"
        }}>{`Sandwich de chola`}</a>{` - Popular street food in La Paz and the name likely comes from the fact that Bolivian cholas are the ones who are frequently selling it`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Peanut_soup"
        }}>{`Sopa de Maní`}</a>{` - Peanut soup with fries...!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://es.wikipedia.org/wiki/Tucumana"
        }}>{`Tucumanas`}</a>{` - It’s like a deep-fried empanada`}</li>
      <li parentName="ul">{`Arroz con queso`}</li>
      <li parentName="ul">{`Bife de Chorizo - The best cut of meat`}</li>
      <li parentName="ul">{`Llama Chicharrón`}</li>
      <li parentName="ul">{`Mondongo - Spice pork stew famous in Sucre`}</li>
    </ul>
    <h2 {...{
      "id": "la-paz"
    }}>{`La Paz`}</h2>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.gustu.bo/es"
        }}>{`Gusto`}</a>{` - Open kitchen tour was nice`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/KFaERXWjArfCZhem8"
        }}>{`Rendezvous Restaurant`}</a>{` - Tried the llama fillet. Chocolate soufflé was weird.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://polloscopacabana.com/"
        }}>{`Pollos Copacabana`}</a>{` - Bolivian fried chicken chain!`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.miteleferico.bo/"
        }}>{`Mi Teleférico`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Subway in the sky; Great views just riding it around!`}</li>
          <li parentName="ul">{`Mirador Qhana Pata - Sunset view was amazing`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/G28L7gPTMWqj3Z3Y8"
        }}>{`Calle Sagarnaga`}</a>{` - Lots of tour operators here and lots of llama keychains for sale as well`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/GheqpGjBR5Q4yJy47"
            }}>{`Coca Museum`}</a>{` - Spent most of my time reading a translated booklet`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/46itfGpSETCkiBuF6"
            }}>{`The Witches’ Market`}</a>{` - Need a potion?`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/FwZKwE6fr3n5HHF49"
            }}>{`San Francisco Museum`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/JJkxxJ2BYYprARcB9"
            }}>{`Ethnography Museum`}</a>{` - No English available (when I was there at least)`}</li>
        </ul>
      </li>
    </ul>
    <h4 {...{
      "id": "excursions"
    }}>{`Excursions`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/BcTEgf5cTw11NcX78"
        }}>{`Huyana Potosi`}</a>{` - They call this the "easiest" 6k but I'd say its relative. I did a 3 day trek which included some practice with an ice pick. On the last day, I started around midnight but gave up at ~5850m or so. Highly suggest booking with a tour operator that lodges at the camp closest to the summit. It was sold out and I ended up going with a company called 'All Transport' but I didn't like my guide that much.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/FEfGGgG8CvY2gVnRA"
        }}>{`Austria Peak`}</a>{` - I only did a short hike but it was nice`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/A9NqpikRABvTZ9nX8"
        }}>{`Valle de la Luna`}</a>{` - Half hour south of La Paz. I hired a private cab who waited for me. There's only 2 circuits to choose from. 45 minutes for the full and the shortcut was 15 minutes. I did both.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/BSwHJe1fnN6Dkj218"
        }}>{`Tiwanaku`}</a>{` - A skip for me...`}</li>
    </ul>
    <h2 {...{
      "id": "uyuni--tupiza"
    }}>{`Uyuni & Tupiza`}</h2>
    <ul>
      <li parentName="ul">{`I recommend starting the Salar de Uyuni tour in Tupiza!`}</li>
      <li parentName="ul">{`Food options are very limited`}</li>
    </ul>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/Twq4tKvbErwR3Vs79"
        }}>{`Alamo`}</a>{` - Tupiza`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Salchipapas"
        }}>{`Salchipapas`}</a>{` - Street vendor`}</li>
      <li parentName="ul">{`Chicharron de Llama - Street vendor`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <h4 {...{
      "id": "horseback-riding"
    }}>{`Horseback Riding`}</h4>
    <ul>
      <li parentName="ul">{`3 hour tour is more than enough. The 5 hour tour was too long for me.`}</li>
      <li parentName="ul">{`Explores the incredible landscape of `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/gXatMd1DMZYdrCBS8"
        }}>{`Cañón del Inca`}</a>{` via Quebrada Palmira. Rock formation of  Puerta del Diablo, Valle de los Machos and `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/BscHFBcX1kgdW8NE8"
        }}>{`Cañón del Duende`}</a></li>
    </ul>
    <h4 {...{
      "id": "salar-de-uyuni"
    }}>{`Salar de Uyuni`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/EECXPbX3Y9LdF2DB9"
        }}>{`Tupiza Tours`}</a>
        <ul parentName="li">
          <li parentName="ul">{`4 day tour, highly recommended. Starts in Tupiza and finishs with a sunrise in Uyuni`}</li>
          <li parentName="ul">{`There are no luxury accommodations and all the lodges are very basic`}</li>
          <li parentName="ul">{`Booking online wasn't great so I went it in person`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "potosi"
    }}>{`Potosi`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/kH43uBxBHgALEpMw5"
        }}>{`Cerro Rico`}</a>{` is the only reason I stayed the night here. It's an ethnical decision but I did a 3 hour tour with `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/kZQnGXLMNYRQrkxK9"
        }}>{`Potochij tours`}</a>{` and thought it was very educational.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/pFRZg6tiCjSq8FUE9"
        }}>{`Casa Nacional de Moneda`}</a>{` - Guided English tour`}</li>
    </ul>
    <h2 {...{
      "id": "sucre"
    }}>{`Sucre`}</h2>
    <ul>
      <li parentName="ul">{`Daily siesta from 12:30-3PM and Sundays were dead.`}</li>
    </ul>
    <h3 {...{
      "id": "food-3"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/TZ1VFd4PdiyT2LBZ7"
        }}>{`Mercado Central`}</a>{` - Lovely local market`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/s5NjppSTtmRgdVGU8"
        }}>{`Condor Cafe`}</a>{` - There's a tour operator in the back`}</li>
    </ul>
    <h4 {...{
      "id": "chocolate"
    }}>{`Chocolate`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/P3kifoWS6FD7VBLh7"
        }}>{`Para Ti chocolate`}</a>{` - I loved the “Relleno con Crema Blanca y Waffles” truffle`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/51QvRGkg2NqYcB8J9"
        }}>{`Taboada`}</a></li>
      <li parentName="ul">{`Chocolate Sucre - I have a thing for sweets`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-2"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/q94ZeLj8zh6bHuoz5"
        }}>{`Museo de Arte Indigena`}</a>{` - Was very informative and lots of fair trade tapestries for sale`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/5JL48Vxqha6BAWj88"
        }}>{`Museo del Tesoro`}</a>{` - English tour available`}</li>
    </ul>
    <h2 {...{
      "id": "santa-cruz"
    }}>{`Santa Cruz`}</h2>
    <ul>
      <li parentName="ul">{`Not my kind of city but they have an international airport (which sucked)`}</li>
    </ul>
    <h3 {...{
      "id": "food-4"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/tbMQef72ugYkYY5TA"
        }}>{`VacaFria`}</a>{` - Tres leche con brownie`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/9Qm9BpVkXspuq3ya9"
        }}>{`Picolo`}</a>{` - What could possible go wrong with Ferrero Rocher flavor?!`}</li>
      <li parentName="ul">{`Burger King - HAH`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      